import React from 'react'
import PropTypes from 'prop-types'
import {Link, graphql, StaticQuery, useStaticQuery} from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import * as Chakra from '@chakra-ui/react'
import Fuse from "fuse.js";

const GuideRoll = ({limit}) => {

    const data = useStaticQuery(graphql`
        query BlogRollQuery {
            allMarkdownRemark(
                sort: { order: DESC, fields: [frontmatter___date] }
                filter: { frontmatter: { templateKey: { eq: "guide" } } }
            ) {
                edges {
                    node {
                        excerpt(pruneLength: 400)
                        id
                        fields {
                            slug
                        }
                        frontmatter {
                            title
                            description
                            templateKey
                            date(formatString: "MMMM DD, YYYY")
                            featuredpost
                            featuredimage {
                                childImageSharp {
                                    fluid(maxWidth: 120, quality: 100) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    const [query, setQuery] = React.useState<string>("");

    const { edges: posts } = data.allMarkdownRemark

    const fuse = new Fuse(posts, {
        isCaseSensitive: false,
        includeScore: true,
        shouldSort: true,
        includeMatches: true,
        findAllMatches: false,
        minMatchCharLength: 1,
        location: 0,
        threshold: 0.3,
        distance: 100,
        useExtendedSearch: false,
        ignoreLocation: false,
        ignoreFieldNorm: false,
        keys: ['node.frontmatter.title', 'node.frontmatter.description', 'node.excerpt', 'node.fields.slug']
    });

    const preresults = query ? fuse.search(query).map(returnable => returnable.item) : posts;
    const results = limit ? preresults.slice(0, limit) : preresults

    return (
        <>
            <Chakra.InputGroup pb="40px">
                <Chakra.Input placeholder='Search...' value={query} onChange={(e)=> { setQuery(e.target.value); }} />
                <Chakra.InputRightElement>
                    <Chakra.CloseButton size="sm" onClick={()=>{ setQuery("")}} opacity={query ? 1 : 0} transition="0.2s" />
                </Chakra.InputRightElement>
            </Chakra.InputGroup>
            <Chakra.SimpleGrid columns={{base: 1, md: 2, xl: 3}} gap="40px">
                {results &&
                results.map(({ node: post }) => {

                    let isFeatured = post.frontmatter.featuredpost;
                    let featuredImage = post.frontmatter.featuredimage;
                    let link = post.fields.slug

                    return (
                        <Chakra.GridItem key={post.id} boxShadow="lg" p="15px" borderRadius="6px" _hover={{transform: "translateY(-8px)"}} transition="0.3s" as={Link} to={link}>
                            <article>
                                <Chakra.Box position="relative">
                                    <PreviewCompatibleImage
                                        imageInfo={{
                                            image: featuredImage ? featuredImage : "https://source.unsplash.com/1600x900/?tech,coding",
                                            alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                                        }}
                                    />
                                    <Chakra.Badge px="10px" position="absolute" top="10px" right="10px">
                                        {post.frontmatter.date}
                                    </Chakra.Badge>
                                </Chakra.Box>
                                <Chakra.Heading as="header" size="md" px="20px" pt="20px">
                                    {post.frontmatter.title}
                                </Chakra.Heading>
                                <Chakra.Text p="20px">
                                    {post.frontmatter.description}
                                </Chakra.Text>
                            </article>
                        </Chakra.GridItem>
                    )
                })}
            </Chakra.SimpleGrid>
        </>
    )
}

GuideRoll.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
}

export default GuideRoll