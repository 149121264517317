import React from "react";
import * as Chakra from "@chakra-ui/react";
import {PageProps} from "gatsby";
import { Seo } from "../../components/seo";
import GuideRoll from "../../components/guide-roll";

const Index = ({ location }: PageProps): React.ReactElement => (
  <Chakra.Box width="100%">
    <Seo title="Audal Labs Boilerplate" location={location} />
      <Chakra.Box px="40px">
          <Chakra.Flex flexDirection="column" alignItems="center" justifyContent="center" pb="80px" m="auto">
              <Chakra.Heading letterSpacing="-0.11px" py="40px">Find what you need.</Chakra.Heading>
              <GuideRoll />
          </Chakra.Flex>
      </Chakra.Box>
  </Chakra.Box>
);

export default Index;
